angular.module("aerosApp")
	.factory('addUserModal', ["$uibModal", function ($uibModal) {
		function open(projectName, userList, availableRoles, addNew) {
			var modal = $uibModal.open({

                size: "md",
				templateUrl: "/static/templates/project/add-user-modal.html",
				controller: "AddUserModalCtrl",
				resolve: {
					projectName: function () { return projectName; },
					userList: function () { return userList; },
					availableRoles: function () { return availableRoles; },
					addNew: function () { return addNew; }
				}
			});
			return modal.result;
		}
		return {
			open: open
		};
	}])
	.controller("AddUserModalCtrl", [ "$scope", "$uibModalInstance", "projectName", "userList", "availableRoles", "addNew", 
     function($scope, $uibModalInstance, projectName, userList, availableRoles, addNew) {
		$scope.projectName = projectName;
		$scope.users = userList;
		$scope.availableRoles = availableRoles;
		$scope.addNew = function () {
			addNew();
			$scope.addUsersToProject();
		};

		deselectUsers();

		$scope.cancel = function() {
			$uibModalInstance.dismiss();
		};

		$scope.addUsersToProject = function() {
			var selectedUsers = _.filter(userList, {'selected': true});
			$uibModalInstance.close(selectedUsers);
		};

		function deselectUsers() {
			_.forEach(userList, function(user) {
				user.selected = false;
			});
		}
		$scope.selectedRoleRankByUser = {};
		$scope.toggleRole = function (user, role) {
			if (user.roles[0] === role.id) {
				user.roles = [];
				delete $scope.selectedRoleRankByUser[user.email];
			} else {
				user.roles = [role.id];
				$scope.selectedRoleRankByUser[user.email] = role.rank;
			}
		    user.selected = !!user.roles.length;
		}; 
		$scope.showRole = function (user, role) {
			var userRank = $scope.selectedRoleRankByUser[user.email];
			return (typeof userRank !== "undefined" &&  role.rank >= userRank);
		};
		
		$scope.checkIfAnyUserIsSelected = function(){
			var state = false;
			_.forEach($scope.users, function(user) {
				if(user.selected){
					state = true;
					return state;
				}
			})
			return state;
		}
		
	}]);